import { useEffect, useState } from 'react'
import './Register.css'
import apiHost from '../Assets/api.json'
import Select from 'react-select'
import UserTable from './UserTable'
import translations from '../Assets/translations.json'

const roleOptions = [
  {'value': 'user', 'label': 'user'},
  {'value': 'admin', 'label': 'admin'},
  {'value': 'viewer', 'label': 'viewer'}
]

function Register({ isOpen, username, language, role}) {
  const [newUser, setNewUser] = useState('')
  const [newRole, setNewRole] = useState('user')
  const [pwd, setPwd] = useState('')
  const [pwd2, setPwd2] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [bannerMsg, setBannerMsg] = useState('The form is invalid.')
  const [filterUser, setFilterUser] = useState('')
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setReload(old => !old)
  }, [language])
  
  if (role !== 'admin') {
    return <></>
  }


  const handleSend = () => {
    if (newUser === '') {
      setIsValid(false)
      setBannerMsg(translations[language]['register']['banner']['user_void'])
      return
    }
    if (pwd !== pwd2) {
      setIsValid(false)
      setBannerMsg(translations[language]['register']['banner']['pwd_mismatch'])
      return
    }
    if (pwd === '') {
      setIsValid(false)
      setBannerMsg(translations[language]['register']['banner']['pwd_void'])
      return
    }
    fetch(`${apiHost[apiHost['on_use']]}add-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: newUser, role: newRole, pwd: pwd})
    }).then(response => response.json())
    .then(data => {
      setNewUser('')
      setNewRole('user')
      setPwd('')
      setPwd2('')
      setIsValid(true)
      setReload(old => !old)
    })
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') handleSend()
  }

  const handleFilter = () => {
    setReload(old => !old)
  }

  const getUsernames = async () => {
    const response = await fetch(`${apiHost[apiHost['on_use']]}get-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user_pattern: filterUser})
    })
    const data = await response.json()
    return {apiData: data, apiCols: [
      {Header: translations[language]['register']['user'], accesor: 'username', editable: false},
      {Header: translations[language]['register']['role'], accesor: 'role', editable: true, options: roleOptions}
    ]}
  }

  const deleteUser = (user) => {
    fetch(`${apiHost[apiHost['on_use']]}delete-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: user})
    })
  }

  const updateUser = (user, updatedRole) => {
    fetch(`${apiHost[apiHost['on_use']]}update-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: user, role: updatedRole})
    })
  }

  return <>
  <div className={`registerContainer ${isOpen ? 'open' : ''}`}>
    <span>{translations[language]['register']['user']}</span>
    <input 
      type='text'
      placeholder={translations[language]['register']['user_placeholder']}
      onChange={(e) => {setNewUser(e.target.value)}}
      onKeyDown={handleEnter}
      value={newUser}
    />
    <span>{translations[language]['register']['role']}</span>
    <Select
      className='rolSelect'
      value={{'value': newRole, 'label': newRole}}
      onChange={option => {setNewRole(option['value'])}}
      options={roleOptions}
      placeholder={translations[language]['register']['role_placeholder']}
    />
    <span>{translations[language]['register']['pwd']}</span>
    <input 
      type='password'
      placeholder={translations[language]['register']['pwd_placeholder']}
      onChange={(e) => {setPwd(e.target.value)}}
      onKeyDown={handleEnter}
      value={pwd}
    />
    <span>{translations[language]['register']['pwd2']}</span>
    <input 
      type='password'
      placeholder={translations[language]['register']['pwd2_placeholder']}
      onChange={(e) => {setPwd2(e.target.value)}}
      onKeyDown={handleEnter}
      value={pwd2}
    />
    {!isValid &&
      <div className="banner">{bannerMsg}</div>
    }
    <button className="register" onClick={handleSend}>{translations[language]['register']['button']}</button>
  </div>
  <div className={`filterContainer ${isOpen ? 'open' : ''}`}>
    <input 
      type='text'
      placeholder={translations[language]['register']['filter_input']}
      onChange={e => {setFilterUser(e.target.value)}}
      onKeyDown={e => {if (e.key === 'Enter') handleFilter()}}
      value={filterUser}
    />
    <button onClick={handleFilter}>{translations[language]['register']['filter_button']}</button>
  </div>
  <UserTable 
    className='userTable'
    isOpen={isOpen}
    reload={reload}
    fetchData={getUsernames}
    deleteRow={row => deleteUser(row.original.username)}
    updateRow={(old, new_) => updateUser(old.username, new_.role)}
  />
  </>
}

export default Register