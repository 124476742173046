import './Upload.css'
import React, { useState } from 'react'
import DragAndDrop from './DragAndDrop'
import Metadata from './Metadata.jsx'
import EditableTable from './EditableTable'
import extraMetadata from './extraMetadata.json'

function FileUploader({ isOpen, username, language,role }) {
  const [existsMetadata, setExistsMetadata] = useState(false)
  const [metadata, setMetadata] = useState({
    'language': '',
    ...Object.keys(extraMetadata).reduce((acc, key) => {
      acc[key] = '';
    return acc;
    }, {})
  })
  const [numUploaded, setNumUploaded] = useState(0);

  if (role === "admin") {
    return <>
    <Metadata 
      setExistsMetadata={setExistsMetadata} 
      isOpen={isOpen} 
      metadata={metadata} 
      setMetadata={setMetadata} 
      language={language}/>
    {existsMetadata &&
      <DragAndDrop 
        isOpen={isOpen} 
        username={username} 
        metadata={metadata} 
        setNumUploaded={setNumUploaded}
        language={language}/>
    }
    <EditableTable isOpen={isOpen} numUploaded={numUploaded}/>
    </>
  } else {
    return <></>
  }
}

export default FileUploader
