import { useEffect, useState } from 'react'
import './ChangePwd.css'
import translations from '../Assets/translations.json'
import apiHost from '../Assets/api.json'

function ChangePwd({ isOpen, username, language, role}) {
  const [currUser, setCurrUser] = useState(username)
  const [pwd, setPwd] = useState('')
  const [pwd2, setPwd2] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [bannerMsg, setBannerMsg] = useState('The form is invalid.')

  // Used to reset when logout and login occurs
  useEffect(() => {
    setCurrUser(username)
    setPwd('')
    setPwd2('')
    setIsValid(true)
  }, [username])

  const handleSend = () => {
    if (role === 'admin') {
      if (currUser === '') {
        setIsValid(false)
        setBannerMsg(translations[language]['register']['banner']['user_void'])
        return
      }
    }
    if (pwd !== pwd2) {
      setIsValid(false)
      setBannerMsg(translations[language]['register']['banner']['pwd_mismatch'])
      return
    }
    if (pwd === '') {
      setIsValid(false)
      setBannerMsg(translations[language]['register']['banner']['pwd_void'])
      return
    }

    fetch(`${apiHost[apiHost['on_use']]}change-pwd`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: currUser, new_password: pwd})
    }).then(response => {
      if (response.status === 500) {
        setBannerMsg(translations[language]['register']['banner']['server_error'])
        setIsValid(false)
      }
      return response.json()
    })
    .then(data => {
      console.log(data)
      if (data.message === 'user does not exists'){
        setBannerMsg(translations[language]['register']['banner']['user_not_exists'])
        setIsValid(false)
      } else  {
        if (role === 'admin') {
          setCurrUser(username)
        }
        setPwd('')
        setPwd2('')
        setIsValid(true)
      }
    }).catch(error => {
      console.error('Error updating pwd:', error)
    })
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') handleSend()
  }

  return <div className={`changePwdContainer ${isOpen ? 'open' : ''}`}>
  {role === 'admin' && 
  <>
    <span>{translations[language]['register']['user']}</span>
    <input 
      type="text" 
      placeholder={translations[language]['register']['user_placeholder']}
      value={currUser}
      onChange={e => setCurrUser(e.target.value)}
      onKeyDown={handleEnter}
    />
  </>
  }
  <span>{translations[language]['register']['pwd']}</span>
  <input 
    type="password" 
    placeholder={translations[language]['register']['pwd_placeholder']}
    value={pwd}
    onChange={e => setPwd(e.target.value)}
    onKeyDown={handleEnter}
  />
  <span>{translations[language]['register']['pwd2']}</span>
  <input 
    type="password" 
    placeholder={translations[language]['register']['pwd2_placeholder']}
    value={pwd2}
    onChange={e => setPwd2(e.target.value)}
    onKeyDown={handleEnter}
  />
  {!isValid &&
    <div className="banner">{bannerMsg}</div>
  }
  <button onClick={handleSend}>{translations[language]['change']}</button>
  </div>
}

export default ChangePwd