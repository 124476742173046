import './UserTable.css'
import React, { useEffect, useState } from 'react'
import { useReactTable, flexRender, getCoreRowModel, createColumnHelper } from '@tanstack/react-table'

const TableCell = ({ getValue, row, column, table }) => {
  const tableMeta = table.options.meta
  const columnMeta = column.columnDef.meta
  const initialValue = row.original[columnMeta?.accesor]
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onChange = (e) => {
    setValue(e.target.value)
    tableMeta?.updateData(row.index, columnMeta?.accesor, e.target.value)
  }

  if (tableMeta?.editedRows[row.id] && columnMeta.editable) {
    return <select onChange={onChange} value={value}>
        {columnMeta?.options?.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
        ))}
    </select>
  }
  return <span>{value}</span>
}

const EditCell = ({ row, table }) => {
  const meta = table.options.meta
  const setEditedRows = (e) => {
    const elName = e.currentTarget.name
    meta?.setEditedRows((old) => {
      return {
        ...old,
        [row.id]: !old[row.id],
      }
    })
    
    if (elName !== "edit") {
      if (elName !== "delete"){
        meta?.revertData(row.index, e.currentTarget.name === "cancel")
      } else {
        meta?.deleteData(row)
      }
    }
  }

  return (
    <div className="edit-cell-container">
      {meta?.editedRows[row.id] ? (
        <div className="edit-cell">
          <button onClick={setEditedRows} name="cancel">
            X
          </button>{' '}
          <button onClick={setEditedRows} name="done">
            ✔
          </button>
          <button onClick={setEditedRows} name="delete">
            🗑️
          </button>
        </div>
      ) : (
        <button onClick={setEditedRows} name="edit">
          ✐
        </button>
      )}
    </div>
  )
}

const UserTable = ({ isOpen, reload, fetchData, deleteRow, updateRow }) => {
  const [data, setData] = useState([() => []])
  const [columns, setColumns] = useState([])
  const [originalData, setOriginalData] = useState(() => [])
  const [editedRows, setEditedRows] = useState({})

  useEffect(() => {
    const loadData = async () => {
      const { apiData, apiCols }  = await fetchData()
      setData( [...apiData])
      setOriginalData( [...apiData])

      const columnHelper = createColumnHelper()
      const copy = apiCols.map((el) => {
        return columnHelper.display({
          header: el.Header,
          cell: TableCell,
          meta: {type: 'text', accesor: el.accesor, editable: el.editable, options: el.options}
        })
      })
      setColumns([...copy, 
        columnHelper.display({
          id: "edit",
          cell: EditCell,
        })
      ])
    }

    loadData()
  }, [reload])
  
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editedRows,
      setEditedRows,
      revertData: (rowIndex, revert) => {
        if (revert) {
          setData((old) =>
            old.map((row, index) =>
              index === rowIndex ? originalData[rowIndex] : row
            )
          )
        } else {
          updateRow(originalData[rowIndex], data[rowIndex])
          setOriginalData((old) =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
          )
        }
      },
      deleteData: (row) => {
        const rowIndex = row.index
        setData(old => old.filter((row, index) => index !== rowIndex))
        setOriginalData(old => old.filter((row, index) => index !== rowIndex))  
        deleteRow(row)
      },
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  return (
    <div className={`usertableContainer ${isOpen ? 'open' : ''}`}>
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  )
}

export default UserTable