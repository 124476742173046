
import OpenAI from "openai"
import translations from '../Assets/translations.json'
import apiHost from '../Assets/api.json'

export async function count_tokens(txt) {
  return await fetch(`${apiHost[apiHost['on_use']]}count-tokens`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({prompt: txt})
  }).then(response => response.json())
  .then(data => data.num_tokens)
}

const key = await fetch(`${apiHost[apiHost['on_use']]}get-api-key`, {
  method: 'GET',
  headers: {'Content-Type': 'application/json'}
}).then(response => response.json())
.then(data => data.key)
const openai = new OpenAI({ apiKey: key, dangerouslyAllowBrowser: true})

// Function to check if the prompt needs context
async function checkNeedsContext(prompt) {
  const response = await fetch(`${apiHost[apiHost['on_use']]}needs-context`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ prompt: prompt }),
  })
  const data = await response.json()
  return [data.result, data.num_tokens] // Assuming the endpoint returns { result: true/false }
}

// Function to get context for the prompt
async function getContext(prompt, history, filterLanguage, language, filterMetadata) {
  const response = await fetch(`${apiHost[apiHost['on_use']]}get-context`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      prompt: prompt, 
      history: history, 
      filterLanguage: filterLanguage, 
      language: language,
      filterMetadata: filterMetadata
    }),
  })
  const data = await response.json()
  return [data.result, data.num_tokens]// Assuming the endpoint returns context in { result: ... }
}

// Function to get context for the prompt
async function isAnswer(prompt, context) {
  const response = await fetch(`${apiHost[apiHost['on_use']]}is-answer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ prompt: prompt, context: context }),
  })
  const data = await response.json()
  return [data.result, data.num_tokens] // Assuming the endpoint returns context in { result: ... }
}

export async function sendMsgToOpenAI({ 
  messages, 
  prompt, 
  language, 
  role, 
  useRag,
  model,
  temperature,
  filterLanguage,
  filterMetadata
  }) {
  let num_tokens = 0
  let modified_prompt
  if (useRag){
    const [needsContext, consumed_tokens1] = await checkNeedsContext(prompt)
    num_tokens += consumed_tokens1
    if (needsContext) {
      let [context, consumed_tokens2] = await getContext(prompt, messages, filterLanguage, language, filterMetadata)
      num_tokens += consumed_tokens2
      if (role === 'viewer') {
        const regex = /La URL \/ enlace es: http:\/\/.+?\/downloads\/[^#]+#page=/gm
        context = context.replace(regex, "")
        console.log("Replaced ", context)
      }
      let [is_answer, consumed_tokens3] = await isAnswer(prompt, context)
      num_tokens += consumed_tokens3
      console.log(is_answer)
      if (is_answer) {
        // Proceed with the context for your prompt
        if (role === 'admin' || role === 'user'){
          modified_prompt = translations[language]['modified'].replace('{{prompt}}', prompt).replace('{{context}}', context)
        } else {
          modified_prompt = translations[language]['modified_viewer'].replace('{{prompt}}', prompt).replace('{{context}}', context)
        }
      } else {
        modified_prompt = translations[language]['modified_question'].replace('{{prompt}}', prompt)
      }
    } else {
      console.log('No additional context needed.')
      // Proceed without additional context
      modified_prompt = prompt
    }
  } else {
    modified_prompt = prompt
  }
  console.log('prompt:', modified_prompt)

  let messages_copy = [...messages]
  let new_messages = [
    { role: "system", content: translations[language]['system'] },
    ...messages_copy,
    { role: "user", content: modified_prompt },
  ]
  let history_tokens = 0
  for (const msg of new_messages) {
    history_tokens += await count_tokens(msg['content'])
  }
  while (history_tokens >= 4096 && messages_copy.length > 0) {
    const removed = messages_copy.shift()
    history_tokens -= await count_tokens(removed['content'])
  }
  new_messages = [
    { role: "system", content: translations[language]['system'] },
    ...messages_copy,
    { role: "user", content: modified_prompt },
  ]
  num_tokens += history_tokens
  const stream = await openai.chat.completions.create({
    messages: new_messages,
    model: model,
    temperature: temperature,
    stream: true,
  })
  return { stream, num_tokens }
}