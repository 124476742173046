import './App.css'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Cookies from 'js-cookie'
import ChatBot from './Components/ChatBot/ChatBot'
import LoginSignup from './Components/LoginSignup/LoginSignup'
import Sidebar from './Components/SideBar/SideBar'
import FileUploader from './Components/Upload/Upload'
import apiHost from './Components/Assets/api.json'
import translations from './Components/Assets/translations.json'
import Register from './Components/Register/Register'
import ChangePwd from './Components/ChangePwd/ChangePwd'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [username, setUsername] = useState('')
  const [role, setRole] = useState('')
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const [language, setLanguage] = useState('spanish')
  const [messages, setMessages] = useState([
    {
      content: translations[language]['greeting'],
      role: 'assistant',
    }
  ])
  const [chatId, setChatId] = useState(null)
  const [reloadChats, setReloadChats] = useState(false)

  // Check for session cookie
  useEffect(() => {
    setIsLoading(true) // Start loading
    const token = Cookies.get('authToken')
    if (token) {
      fetch(`${apiHost[apiHost['on_use']]}decode-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.role) {
          console.log('Token is valid', data)
          setIsLoggedIn(true)
          setUsername(data.username)
          setRole(data.role)
          fetch(`${apiHost[apiHost['on_use']]}get-language`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: data.username})
          }).then(response => response.json())
          .then(data => {
            console.log('language:', data.language)
            if (data.language) setLanguage(data.language)
            else setLanguage('spanish')
          })
          .catch(error => {
            console.error('Error getting language:', error)
          })
        } else {
          console.error('Token is invalid')
          setIsLoggedIn(false)
        }
        setIsLoading(false) // Stop loading once the validation is complete
      })
      .catch(error => {
        console.error('Error decoding token:', error)
        setIsLoading(false) // Ensure loading is stopped even if there's an error
      })

    } else {
      setIsLoading(false) // No token, so stop loading immediately
    }
  }, [])

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen)
  }

  // This function is called to simulate a successful login/signup
  const onLoginSuccess = () => {
    setIsLoggedIn(true)
  }
  if (isLoading) {
    return (
      <div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgb(3,0,31)' }}></div>
    )
  } else {
    // Render your app's content based on isAuthenticated state
    return (
      <>
        {isLoggedIn ? <Router>
          <Sidebar 
            isOpen={isSidebarOpen} 
            toggle={toggleSidebar} 
            setLanguage={setLanguage} 
            language={language}
            role={role}
            username={username}
            setMessages={setMessages}
            setChatId={setChatId}
            reloadChats={reloadChats}
            />
      <Routes>
        <Route path="/" element={<ChatBot 
              username={username} 
              isOpen={isSidebarOpen} 
              language={language}
              role={role}
              messages={messages}
              setMessages={setMessages}
              chatId={chatId}
              setChatId={setChatId}
              setReloadChats={setReloadChats}
              />} />
        <Route path="/upload" element={<FileUploader 
                isOpen={isSidebarOpen} 
                username={username} 
                language={language}
                role={role}
                messages={messages}
                setMessages={setMessages}
                chatId={chatId}
                setChatId={setChatId}
                setReloadChats={setReloadChats}
              />} />

        <Route path="/register" element={<Register 
                isOpen={isSidebarOpen} 
                username={username} 
                language={language}
                role={role}
                messages={messages}
                setMessages={setMessages}
                chatId={chatId}
                setChatId={setChatId}
                setReloadChats={setReloadChats}
              />} />
        <Route path="/change-pwd" element={<ChangePwd 
                isOpen={isSidebarOpen} 
                username={username} 
                language={language}
                role={role}
                messages={messages}
                setMessages={setMessages}
                chatId={chatId}
                setChatId={setChatId}
                setReloadChats={setReloadChats}
              />} />
            {/* Puedes agregar más rutas según necesites */}
          </Routes>
        </Router> : <LoginSignup onLoginSuccess={onLoginSuccess} />}
      </>
    )
  }

}

export default App
