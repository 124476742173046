import './EditableTable.css'
import React, { useEffect, useState } from 'react'
import { useReactTable, flexRender, getCoreRowModel, createColumnHelper } from '@tanstack/react-table'
import apiHost from '../Assets/api.json'

// Simulación de la carga de datos desde una API
const fetchData = async () => {
  const response = await fetch(`${apiHost[apiHost['on_use']]}get-docs`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const docsInfo = await response.json()
  return { apiData: JSON.parse(docsInfo.data), apiCols: docsInfo.columns }
}

const TableCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue()
  const columnMeta = column.columnDef.meta
  const tableMeta = table.options.meta
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value)
  }

  const onChange = (e) => {
    setValue(e.target.value)
    tableMeta?.updateData(row.index, column.id, e.target.value)
  }

  if (tableMeta?.editedRows[row.id]) {
    return columnMeta?.type === "select" ? (
      <select onChange={onChange} value={initialValue}>
        {columnMeta?.options?.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    ) : (
      <input
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        type={columnMeta?.type || "text"}
      />
    )
  }
  return <span>{value}</span>
}

const deleteDocs = (hashes) => {
  fetch(`${apiHost[apiHost['on_use']]}delete-docs`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ hashes: hashes})
  })
}

const EditCell = ({ row, table }) => {
  const meta = table.options.meta
  const setEditedRows = (e) => {
    meta?.setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id],
    }))
    const elName = e.currentTarget.name
    if (elName !== "edit") {
      if (elName !== "delete"){
        meta?.revertData(row.index, e.currentTarget.name === "cancel")
      } else {
        deleteDocs([row.original.hash])
        meta?.deleteData(row.index)
      }
    }
  }

  return (
    <div className="edit-cell-container">
      {meta?.editedRows[row.id] ? (
        <div className="edit-cell">
          <button onClick={setEditedRows} name="cancel">
            X
          </button>{' '}
          <button onClick={setEditedRows} name="done">
            ✔
          </button>
          <button onClick={setEditedRows} name="delete">
            🗑️
          </button>
        </div>
      ) : (
        <button onClick={setEditedRows} name="edit">
          ✐
        </button>
      )}
    </div>
  )
}

const EditableTable = ({ isOpen, numUploaded }) => {
  const [data, setData] = useState([() => []])
  const [columns, setColumns] = useState([])
  const [originalData, setOriginalData] = useState(() => [])

  const [editedRows, setEditedRows] = useState({})

  useEffect(() => {
    const loadData = async () => {
      const { apiData, apiCols }  = await fetchData()
      setData( [...apiData])
      setOriginalData( [...apiData])

      const columnHelper = createColumnHelper()
      const copy = apiCols.filter((el) => el.accessor !== 'hash').map((el) => {
        return columnHelper.accessor(el.accessor, {
          header: el.Header,
          cell: TableCell,
          meta: {type: 'text'},
        })
      })
      setColumns([...copy, 
      columnHelper.display({
        id: "edit",
        cell: EditCell,
      })])
    }

    loadData()
  }, [numUploaded])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      editedRows,
      setEditedRows,
      revertData: (rowIndex, revert) => {
        if (revert) {
          setData((old) =>
            old.map((row, index) =>
              index === rowIndex ? originalData[rowIndex] : row
            )
          )
        } else {
          setOriginalData((old) =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
          )
          fetch(`${apiHost[apiHost['on_use']]}update-docs`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data[rowIndex])
          })
        }
      },
      deleteData: (rowIndex) => {
        setData(old => old.filter((row, index) => index !== rowIndex));
        setOriginalData(old => old.filter((row, index) => index !== rowIndex));    
      },
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  return (
    <div className={`tableContainer ${isOpen ? 'open' : ''}`}>
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  )
}

export default EditableTable
