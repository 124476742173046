import React from 'react'
import './SideBar.css'
import gptLogo from '../Assets/nemeda_logo.png'
// import addBtn from '../Assets/add-30.png'
import msgIcon from '../Assets/message.svg'
// import home from '../Assets/home.svg'
// import saved from '../Assets/bookmark.svg'
import uploadIcon from '../Assets/upload-icon.svg'
import manageIcon from '../Assets/manage-users.svg'
import changeIcon from '../Assets/change-pwd.svg'
import rocket from '../Assets/rocket.svg'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import translations from '../Assets/translations.json'
import apiHost from '../Assets/api.json'
import SavedChats from './SavedChats'

const optionsMap = Object.keys(translations).map((key) => {
  return {'value': key, 'label': translations[key]['lang_name']}
})

// Function to get chat id
// Duplicated from ChatBot.jsx
async function getChatId(username) {
  const response = await fetch(`${apiHost[apiHost['on_use']]}get-chat-id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username: username }),
  })
  const data = await response.json()
  return data.chat_id // Assuming the endpoint returns context in { result: ... }
}

function Sidebar({ isOpen, toggle, setLanguage, language, role, username, setMessages, setChatId, reloadChats }) {

  const handleLogout = () => {
    // Clear the session cookie
    Cookies.remove('authToken') // Adjust according to how you're managing cookies/session
    // Reload the page to reset the state or redirect to login
    window.location.reload()
  }

  const handleChange = (option) => {
    setLanguage(option['value'])
    fetch(`${apiHost[apiHost['on_use']]}set-language`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username: username, language: option['value']})
      }
    ).then(response => response.json())
    .then(data => {
      if (data.status_code !== 200) {
        console.log(data.message)
      } else {
        console.log(data.message)
      }
    })
    .catch(error => {
      console.error('Error updating language:', error)
    })
  }

  const resetMsg = () => {
    const fetchChatId = async () => {
      try {
        const chat_id = await getChatId(username)
        setChatId(chat_id)
      } catch (error) {
        console.error('Error al obtener el chat ID:', error)
        // Aquí podrías manejar errores, por ejemplo, estableciendo un estado de error
      }
    }
    if (username) {
      fetchChatId()
      setMessages([
        {
          content: translations[language]['greeting'],
          role: 'assistant',
        }
      ])
    }
  }

  return (
    <>
      {/*<button className={`toggle-button  ${isOpen ? 'open' : ''}`} onClick={toggle}>
      <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
    </button>*/}
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="upperSide">
        <div className="upperSideTop"><img src={gptLogo} alt="Logo" className="logo" /></div>
        <Link to="/" className="link" onClick={resetMsg}><button className="tab"><img src={msgIcon} alt="chat"/>{translations[language]['sidebar']['chat']}</button></Link>
        <Link to="/upload" className="link"><button className="tab" value="What is programming?"><img src={uploadIcon} alt="tab" />{translations[language]['sidebar']['upload']}</button></Link>
        <Link to="/register" className="link"><button className="tab" value="What is programming?"><img src={manageIcon} alt="tab" />{translations[language]['sidebar']['register']}</button></Link>
        <Link to="/change-pwd" className="link"><button className="tab" value="What is programming?"><img src={changeIcon} alt="tab" />{translations[language]['sidebar']['change']}</button></Link>
        <div className="upperSideBottom">
        </div>
      </div>
      <div className="middleSide">
        <SavedChats 
          setMessages={setMessages} 
          setChatId={setChatId} 
          username={username}
          language={language}
          reloadChats={reloadChats}
          />
      </div>
      <div className="languageContainer">
        <label className="select-label">{translations[language]['language']}</label>
        <Select
          className='selector'
          value={{'value': language, 'label': translations[language]['lang_name']}}
          onChange={handleChange}
          options={optionsMap}
          styles={{
            option: (base) => ({
              ...base,
              color: 'black',
              fontSize: '12px',
            }),
          }}
        />
      </div>
      <div className="lowerSide">
        {/* <div className="listItems logout" onClick={()=>{setLanguage('spanish')}}><img src={home} alt="Home" className="listItemsImg" />Español</div>
        <div className="listItems logout" onClick={()=>{setLanguage('catalan')}}><img src={saved} alt="Saved" className="listItemsImg" />Catalan</div> */}
        <div className="listItems logout" onClick={handleLogout}><img src={rocket} alt="Upgrade" className="listItemsImg" />SALIR</div>
      </div>
    </div>
    </>
  )
}

export default Sidebar
