import './ChatBot.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import sendBtn from '../Assets/send.svg'
import userIcon from '../Assets/user-icon.png'
import gptImgLogo from '../Assets/nemeda_logo2.png' 
import { sendMsgToOpenAI, count_tokens } from './openai'
import { useState, useEffect, useRef } from 'react'
import { marked } from 'marked'
import translations from '../Assets/translations.json'
import apiHost from '../Assets/api.json'

// Ensure you sanitize the output to prevent XSS attacks
// You might need to install a sanitizer, e.g., DOMPurify
import DOMPurify from 'dompurify'

import LateralButtons from './LateralButtons'
import TechnicalSideBar from './TechnicalSideBar'

// Function to get chat id
// Duplicated also in SideBar.jsx
async function getChatId(username) {
  const response = await fetch(`${apiHost[apiHost['on_use']]}get-chat-id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username: username }),
  })
  const data = await response.json()
  return data.chat_id // Assuming the endpoint returns context in { result: ... }
}

// Function to save the Question and Answer
async function saveQA(prompt, answer, user, chat_id, num_tokens, model, latency, setReloadChats, language) {
  if (language === undefined || language === null) {
    language = 'english'
  }
  fetch(`${apiHost[apiHost['on_use']]}insert-qa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      query: prompt, 
      answer: answer, 
      user: user, 
      chat_id: chat_id,
      num_tokens: num_tokens,
      model: model,
      latency: latency,
      language: language
    }),
  }).then(response => response.json())
  .then(data => {
    if (data.is_first_msg) {
      setReloadChats(old => !old)
    }
  })
}

const ChatBot = ({ username, isOpen, language, role, messages, setMessages, chatId, setChatId, setReloadChats }) => {
  const msgEnd = useRef(null)

  const [input, setInput] = useState('')
  const [isOpenRight, setIsOpenRight] = useState(false)
  const [model, setModel] = useState('gpt-4-0125-preview')
  const [temperature, setTemperature] = useState(0.7)
  const [useRag, setUseRag] = useState(true)
  const [filterLanguage, setFilterLanguage] = useState(true)
  const [filterMetadata, setFilterMetadata] = useState(true)

  // Make the view move to the end after every message
  useEffect(() => {
    msgEnd.current.scrollIntoView()
  }, [messages])

  // Delete messages after language change
  useEffect(() => {
    setMessages([{
      content: translations[language]['greeting'],
      role: 'assistant',
    }])
  }, [language])

  // Get chat id after language change, or username change, or just reloading.
  useEffect(() => {
    // Definir una función asíncrona dentro de useEffect
    const fetchChatId = async () => {
      try {
        const chat_id = await getChatId(username)
        setChatId(chat_id)
      } catch (error) {
        console.error('Error al obtener el chat ID:', error)
        // Aquí podrías manejar errores, por ejemplo, estableciendo un estado de error
      }
    }

    // Llamar a la función asíncrona para ejecutarla
    if(username) { // Asegurar que el username esté definido
      fetchChatId()
    }
  }, [username, language])

  const handleSend = async ({role, useRag}) => {
    const prompt = input
    const msgCopy = messages
    setInput('')
    setMessages([
      ...msgCopy,
      { content: prompt, role: 'user'},
    ])
    const startTime = performance.now()
    let {stream, num_tokens} = await sendMsgToOpenAI({ 
      messages, 
      prompt, 
      language, 
      role, 
      useRag, 
      model, 
      temperature,
      filterLanguage,
      filterMetadata
    })
    let responseContent = ''
    let endTime = -1
    for await (const chunk of stream) {
      if (chunk.choices && chunk.choices.length > 0 && chunk.choices[0].delta.content !== undefined) {
        // Accumulate responses or handle them as needed
        responseContent += chunk.choices[0].delta.content
        if (endTime === -1) {
          endTime = performance.now()
        }
      }
      setMessages([
        ...msgCopy,
        { content: prompt, role: 'user'},
        { content: responseContent, role: 'assistant'}
      ])
    }
    num_tokens += await count_tokens(responseContent)
    saveQA(prompt, responseContent, username, chatId, num_tokens, model, (endTime - startTime) / 1000, setReloadChats, language)
  }

  const handleEnter = async (e) => {
    if (e.key === 'Enter') await handleSend({role, useRag})
  }

  // Create a custom renderer
  const renderer = new marked.Renderer()

  // Override the link rendering method
  renderer.link = (href, title, text) => {
    // Ensure external links open in a new tab
    const target = ' target="_blank"'
    // Include rel="noopener noreferrer" for security reasons
    const rel = href.startsWith('http') ? ' rel="noopener noreferrer"' : ''
    const titleAttr = title ? ` title="${title}"` : ''
    return `<a href="${href}"${titleAttr}${target}${rel}>${text}</a>`
  }

  const createMarkup = (markdownText) => {
    // Convert markdown to HTML with marked, using the custom renderer
    const html = marked(markdownText, { renderer: renderer })
    // Sanitize the HTML with DOMPurify
    const sanitizedHtml = DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })
    return { __html: sanitizedHtml }
  }

  return (
    <div className="App">
      <div className={`main ${isOpen ? 'open' : ''} ${isOpenRight ? 'openRight' : ''}`}>
        <div className="chats">
          {messages.map((message, i) => 
            <div key={i} className={message.role === 'assistant' ? "chat bot" : "chat"}>
              <img className='chatImg' src={message.role === 'assistant' ? gptImgLogo : userIcon} alt="User icon" />
              <p className="txt" dangerouslySetInnerHTML={createMarkup(message.content)}></p>
              <LateralButtons 
                i={i} 
                username={username} 
                chat_id={chatId} 
                isAssistant={message.role === 'assistant' && i > 0}
                text={message.content}/>
            </div>
          )}
          <div ref={msgEnd}></div>
        </div>
        <div className="chatFooter">
          <div className="inp">
            <input type="text" name="" id="" placeholder={translations[language]['inp_placeholder']} value={input} onKeyDown={handleEnter} onChange={(e)=>{setInput(e.target.value)}}/> <button className="send" onClick={()=>{handleSend({role, useRag})}}><img src={sendBtn} alt="send" /></button>
          </div>
          <p>{translations[language]['warning']}</p>
        </div>
      </div>
      <TechnicalSideBar 
        isOpenRight={true} 
        language={language} 
        setIsOpenRight={setIsOpenRight}
        model={model}
        setModel={setModel}
        temperature={temperature}
        setTemperature={setTemperature}
        useRag={useRag}
        setUseRag={setUseRag}
        filterLanguage={filterLanguage}
        setFilterLanguage={setFilterLanguage}
        filterMetadata={filterMetadata}
        setFilterMetadata={setFilterMetadata}
        />
    </div>
  )
}

export default ChatBot
