import React, { useState } from 'react'
import apiHost from '../Assets/api.json'

function updateQAFeedback(feedback, n, username, chat_id) {
  fetch(`${apiHost[apiHost['on_use']]}update-qa-feedback`, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    },
    body: JSON.stringify({ feedback: feedback, n: n, user: username, chat_id: chat_id }),
  })
}

const LateralButtons = ({ i, username, chat_id, isAssistant, text }) => {
  const [like, setLike] = useState(null)
  
  const handleClick = (isLike) => {
    if (isLike) {
      if (like !== null && like) return
      setLike(true)
      updateQAFeedback('👍', i+1, username, chat_id)
    } else if (!isLike) {
      if (like !== null && !like) return
      setLike(false)
      updateQAFeedback('👎', i+1, username, chat_id)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
  }

  return (
    <div className="buttons">
      <button className="copyButton" onClick={handleCopy}><i className="fas fa-copy"></i></button>
      {isAssistant && 
      <>
        <button className={`likeButton ${like === true ? "clicked" : ""}`} onClick={() => handleClick(true)}><i className="fa-solid fa-thumbs-up"></i></button>
        <button className={`dislikeButton ${like === false ? "clicked" : ""}`} onClick={() => handleClick(false)}><i className="fa-solid fa-thumbs-down"></i></button>
      </>
      }
    </div>
  )
}

export default LateralButtons
