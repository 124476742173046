import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import './LoginSignup.css'

import user_icon from '../Assets/person.png'
import email_icon from '../Assets/email.png'
import password_icon from '../Assets/password.png'
import apiHost from '../Assets/api.json'

const LoginSignup = ({ onLoginSuccess }) => {
  useEffect(() => { // used for setting body tag before logging
    const originalStyle = window.getComputedStyle(document.body).cssText
    document.body.style.background = 'linear-gradient(#2a00b7, #42006c)'
    document.body.style.color = 'black'
    document.body.style.height = '100vh'
    return () => {
      document.body.style.cssText = originalStyle
    }
  }, [])

  const [action, setAction] = useState("Login")
  const [username, setUsername] = useState('')
  const [pwd, setPwd] = useState('')
  const [email, setEmail] = useState('')
  const [showMsg, setShowMsg] = useState(false)
  const [showMsgError, setShowMsgError] = useState(false)
  const [wrongUsername, setWrongUsername] = useState(false)
  const [wrongPwd, setWrongPwd] = useState(false)

  const loginUser = async (username, role) => {
    const response = await fetch(`${apiHost[apiHost['on_use']]}encode-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: username, role: role }),
    })
    const data = await response.json()
    if (data.token) {
      // Set the token as a cookie
      Cookies.set('authToken', data.token, { expires: 7, sameSite: 'Strict' }) // Expires in 30 days
    } else {
      console.error('Token was not received')
    }
  }

  const handleLoginSignup = async (action) => {
    if (action === 'Login'){
      // Check for user in backend
      const response = await fetch(`${apiHost[apiHost['on_use']]}check-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: username }),
      })
      const data = await response.json()
      if (data.exists) {
        const response = await fetch(`${apiHost[apiHost['on_use']]}check-pwd`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: username, pwd: pwd }),
        })
        const data = await response.json()
        if (data.correct) {
          loginUser(username, data.role) // persist cookie
          onLoginSuccess() // Call the passed function after successful operation
        } else {
          setWrongPwd(true)
        }
      } else {
        setWrongUsername(true)
      }
    } else {
      if (email !== ''){
        let regex = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        if (regex.test(email)){
          fetch(`${apiHost[apiHost['on_use']]}add-email-to-waitlist`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email})
          }).then(response => response.json())
          .then(data => {
            if (data.status_code === 500) {
              console.error(data.message)
            } else if (data.status_code === 200) {
              console.log(data.message)
            }
          })
          .catch(error => console.error(error))
          setEmail('')
          setShowMsg(true)
        } else {
          setShowMsgError(true)
        }
      }
    }
  }

  const handleEnter = async (e, action) => {
    if (e.key === 'Enter') await handleLoginSignup(action)
  }

  const handleEmailChange = (e) => {
    setShowMsgError(false)
    setShowMsg(false)
    setEmail(e.target.value)
  }

  const handlePwdChange = (e) => {
    setWrongUsername(false)
    setWrongPwd(false)
    setPwd(e.target.value)
  }

  const handleUsernameChange = (e) => {
    setWrongUsername(false)
    setWrongPwd(false)
    setUsername(e.target.value)
  }

  return (
    <div className="container logContainer">
      <div className="header">
          <div className="text">{action}</div>
          <div className="underline"></div>
      </div>
      <div className="inputs">
        {action==="Sign Up" ? <></> :
          <div className="input">
            <img src={user_icon} alt="" />
            <input type="text" placeholder='Name' onChange={handleUsernameChange} onKeyDown={(e)=>{handleEnter(e, 'Login')}}/>
          </div>
        }
        
        {action==="Login" ? <></> :
          <>
          <div className="input">
            <img src={email_icon} alt="" />
            <input type="email" placeholder='Email' onChange={handleEmailChange} onKeyDown={(e)=>{handleEnter(e, 'Sign Up')}} value={email}/>
          </div>
          {showMsg && 
            <div className="emailMsg">
              <span className='yay'>You are added to the waitlist!</span>
            </div>
          }
          {showMsgError && 
            <div className="emailMsg">
              <span className='nay'>Invalid email.</span>
            </div>
          }
          </>
        }
        {action==="Sign Up" ? <></> :
        <>
          <div className="input">
            <img src={password_icon} alt="" />
            <input type="password" placeholder='Password' onChange={handlePwdChange} onKeyDown={(e)=>{handleEnter(e, 'Login')}}/>
          </div>
          {wrongUsername && 
            <div className="emailMsg">
              <span className='nay'>Wrong username.</span>
            </div>
          }
          {wrongPwd && 
            <div className="emailMsg">
              <span className='nay'>Wrong password.</span>
            </div>
          }
        </>
        }
      </div>
      {/* {action==="Sign Up" ? <div></div> : <div className="forgot-password">Lost password? <span>Click here!</span></div>} */}
      <div className="submit-container">
        <div className={action==="Login" ? "submit gray" : "submit" } onClick={action==="Login" ? ()=>{setAction("Sign Up")} : ()=>{handleLoginSignup('Sign Up')}}>Sign Up</div>
        <div className={action==="Sign Up" ? "submit gray" : "submit" } onClick={action==="Sign Up" ? ()=>{setAction("Login")} : ()=>{handleLoginSignup('Login')}}>Login</div>
      </div>
    </div>
  )
}

export default LoginSignup