import './TechnicalSideBar.css'
import Select from "react-select"
import translations from "../Assets/translations.json"
import { useEffect, useMemo } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'

function TechnicalSideBar({
  isOpenRight, setIsOpenRight, 
  language, 
  model, setModel, 
  temperature, setTemperature, 
  useRag, setUseRag,
  filterLanguage, setFilterLanguage,
  filterMetadata, setFilterMetadata
}) {

  useEffect(() => {
    const gptModel = Cookies.get('gptModel')
    if (gptModel) setModel(gptModel)
    const _temperature = Cookies.get('temperature')
    if (_temperature) setTemperature(parseFloat(_temperature))
    const _useRag = Cookies.get('useRag')
    if (_useRag) setUseRag((_useRag === "true" ? true : false))
    const _filterLanguage = Cookies.get('filterLanguage')
    if (_filterLanguage) setFilterLanguage((_filterLanguage === "true" ? true : false))
    const _filterMetadata = Cookies.get('filterMetadata')
    if (_filterMetadata) setFilterMetadata((_filterMetadata === "true" ? true : false))
  }, [])

  const handleChange = (option) => {
    setModel(option['value'])
    Cookies.set('gptModel', option['value'], {expires: 30, sameSite: 'Strict'})
  }

  const options = useMemo(() => {
    return {
      'gpt-4-0125-preview': 'GPT 4',
      'gpt-3.5-turbo': 'GPT 3.5 Turbo',
    }
  }, [])

  const handleSliderChange = (e) => {
    setTemperature(e.target.value / 100)
    Cookies.set('temperature', e.target.value / 100, {expires: 30, sameSite: 'Strict'})
  }

  const handleCheckboxChange = (e) => {
    setUseRag(e.target.checked)
    Cookies.set('useRag', e.target.checked, {expires: 30, sameSite: 'Strict'})
  }

  const handleCheckbox2Change = (e) => {
    setFilterLanguage(e.target.checked)
    Cookies.set('filterLanguage', e.target.checked, {expires: 30, sameSite: 'Strict'})
  }

  const handleCheckbox3Change = (e) => {
    setFilterMetadata(e.target.checked)
    Cookies.set('filterMetadata', e.target.checked, {expires: 30, sameSite: 'Strict'})
  }

  return (
    <>
    <div className={`rightSidebar ${isOpenRight ? 'open' : ''}`}>
      <button className={`toggle-button-right  ${isOpenRight ? 'open' : ''}`} onClick={()=>{setIsOpenRight(!isOpenRight)}}>
        <FontAwesomeIcon icon={isOpenRight ? faChevronRight : faChevronLeft} />
      </button>
      <div className="sidebarContainer">
        <h1 className="sidebarTitle">{translations[language]['rightSidebar']['title']}</h1>
        <label className="selectorRightLabel">{translations[language]['rightSidebar']['selectorLabel']}</label>
        <Select 
          className='selectorRight'
          value={{'value': model, 'label': options[model]}}
          onChange={handleChange}
          options={Object.keys(options).map((key) => {
            return {'value': key, 'label': options[key]}
          })}
          styles={{
          option: (base) => ({
              ...base,
              color: 'black',
              fontSize: '12px',
          }),
          }}
          placeholder={translations[language]['select']}/>

        <div className="checkboxContainer">
          <input type="checkbox" id="sidebarCheckbox" onChange={handleCheckboxChange} checked={useRag}/>
          <label htmlFor="sidebarCheckbox">{translations[language]['rightSidebar']['checkboxLabel']}</label>
        </div>
        <div className="checkboxContainer">
          <input type="checkbox" id="sidebarCheckbox2" onChange={handleCheckbox2Change} checked={filterLanguage}/>
          <label htmlFor="sidebarCheckbox2">{translations[language]['rightSidebar']['checkbox2Label']}</label>
        </div>
        <div className="checkboxContainer">
          <input type="checkbox" id="sidebarCheckbox3" onChange={handleCheckbox3Change} checked={filterMetadata}/>
          <label htmlFor="sidebarCheckbox3">{translations[language]['rightSidebar']['checkbox3Label']}</label>
        </div>

        <div className="sliderContainer">
          <label>{translations[language]['rightSidebar']['sliderLabel']}: {temperature}</label>
          <input 
            type="range" 
            min="0" 
            max="100" 
            value={temperature * 100}
            onChange={handleSliderChange}
            />
        </div>
      </div>
    </div>
  </>
  )
}

export default TechnicalSideBar