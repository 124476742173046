import './SavedChats.css'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import apiHost from '../Assets/api.json'
import translations from '../Assets/translations.json'

function SavedChats({ setMessages, setChatId, username, language, reloadChats }) {

  const [previousChats, setPreviousChats] = useState([
    {title: 'prueba', chat_id: 10000},
    {title: 'prueba', chat_id: 10001},
    {title: 'prueba', chat_id: 10002},
    {title: 'prueba', chat_id: 10003},
  ])

  useEffect(() => {
    async function getPrevChats() {
      const response = await fetch(`${apiHost[apiHost['on_use']]}get-prev-chats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: username})
      })
      const data = await response.json()
      setPreviousChats(data)
    }
    getPrevChats()
  }, [username, reloadChats])

  const handleClick = (chat_id) => {
    setChatId(chat_id)
    async function fetchMessages(chat_id) {
      const response = await fetch(`${apiHost[apiHost['on_use']]}get-msgs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({username: username, chat_id: chat_id})
      })
      const data = await response.json()
      setMessages([{
        content: translations[language]['greeting'],
        role: 'assistant',
      }, ...data])
    }
    fetchMessages(chat_id)
  }

  return (<div className='historyContainer'>
    {
      previousChats.map(({ title, chat_id }) =>
      <Link key={`link${chat_id}`} to="/" className="link" onClick={() => {handleClick(chat_id)}}>
        <div key={`div${chat_id}`} className="savedChatItem">
          <span key={`span${chat_id}`} className="chatTitle">
            {title}
          </span>
        </div>
      </Link>
      )
    }
  </div>)
}

export default SavedChats