import { useEffect } from 'react'
import './Metadata.css'
import Select from 'react-select'
import translations from '../Assets/translations.json'
import extraMetadata from './extraMetadata.json'

const optionsMap = {
  'language': [
    {'value': '', 'label': ''},
    ...Object.keys(translations).map((key) => {
      return {'value': key, 'label': translations[key]['lang_name']}
    })
  ],
  ...Object.keys(extraMetadata).reduce((acc, key) => {
    acc[key] = extraMetadata[key].map((value) => {
      return {'value': value, 'label': value}
    })
    return acc
  }, {})
}

function Metadata({ setExistsMetadata, isOpen, metadata, setMetadata, language }) {
  const handleChange = (key) => (option) => {
    setMetadata({ ...metadata, [key]: option['value'] })
  }

  useEffect(() => {
    const allFilled = Object.values(metadata).every((value) => value !== '')
    if (allFilled) setExistsMetadata(true)
    else setExistsMetadata(false)
  }, [setExistsMetadata, metadata])

  return <div className={`metadataContainer ${isOpen ? 'open' : ''}`} >
    {Object.keys(metadata).map((key) => (<div key={key}className="select-container">
      {key === 'language' && <label key={`label${key}`} className="select-label">{translations[language]['language']}</label>}
      {key !== 'language' && <label key={`label${key}`} className="select-label">{key}</label>}
      <Select
        className='selector'
        key={key}
        defaultValue={''}
        onChange={handleChange(key)}
        options={optionsMap[key]}
        styles={{
          option: (base) => ({
            ...base,
            color: 'black',
            fontSize: '12px',
          }),
        }}
        placeholder={translations[language]['select']}
      />
      </div>
    ))}
  </div>
}

export default Metadata