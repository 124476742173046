import React, { useCallback, useState, useEffect } from 'react'
import translations from '../Assets/translations.json'
import apiHost from '../Assets/api.json'

function DragAndDrop({ isOpen, username, metadata, setNumUploaded, language }) {
  const [dragActive, setDragActive] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({})

  useEffect(() => {
    let progressInterval
    if (uploadProgress > 0 && uploadProgress < 100) {
      progressInterval = setInterval(() => {
        setUploadProgress(oldProgress => {
          if (oldProgress >= 95) {
            clearInterval(progressInterval)
            return 95 // If upload completes, it will jump to 100% from here
          }
          return Math.min(oldProgress + 1, 95) // Increment progress until 95%
        })
      }, 100) // Speed of the asynchronous progress bar (faster = less realistic)
    }

    // Cleanup the interval on unmount or when upload completes
    return () => {
      if (progressInterval) {
        clearInterval(progressInterval)
      }
    }
  }, [uploadProgress])

  const handleUploadComplete = () => {
    // Quickly complete the progress bar
    setNumUploaded((old) => old+1)
    setUploadProgress(100)
    setTimeout(() => {
      // Optionally reset the progress for the next file upload
      setUploadProgress(0)
    }, 500) // Delay for the progress bar to stay at 100%
  }

  // Handle drag events
  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

 // Actualiza la función handleDrop para manejar múltiples archivos
 const handleDrop = (e) => {
  e.preventDefault()
  e.stopPropagation()
  setDragActive(false)
  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    handleFiles(e.dataTransfer.files)
  }
 }

 // Actualiza la función handleChange para manejar múltiples archivos
 const handleChange = (e) => {
  e.preventDefault()
  if (e.target.files && e.target.files.length > 0) {
    handleFiles(e.target.files)
  }
 }
// Actualiza la función handleFiles para manejar múltiples archivos
const handleFiles = (files) => {
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    if (file.type !== 'application/pdf' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      alert('Only PDF and DOCX files are allowed')
      continue
    }
    if (file.size > 200 * 1024 * 1024) {
      alert('File size exceeds limit of 200MB')
      continue
    }
    const formData = new FormData()
    formData.append('item_json', JSON.stringify({
      'username': username,
      'metadata': metadata,
    }))
    formData.append('file', file)
    console.log(formData)

    setUploadProgress(1)

    fetch(`${apiHost[apiHost['on_use']]}upload/`, {
      method: 'POST',
      body: formData,
    })
    .then(response => response.json())
    .then(data => {
      handleUploadComplete()
      console.log(data.message)
    })
    .catch(error => {
      handleUploadComplete()
      console.error('Error uploading file:', error)
      alert('Error uploading file')
    })
  }
}
  // Input reference
  const inputRef = useCallback((node) => {
    if (node) {
      node.addEventListener('dragenter', handleDrag)
      node.addEventListener('dragover', handleDrag)
      node.addEventListener('dragleave', handleDrag)
      node.addEventListener('drop', handleDrop)
    }
  }, [])

  return (
    <div
      className={`uploader ${dragActive ? 'active' : ''} ${isOpen ? 'open' : ''}`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
      ref={inputRef}
    >
      <div className="upload-icon"></div>
      <p>{translations[language]['drag']}</p>
      <p>{translations[language]['limit']}</p>
      <input
        type="file"
        id="file-input"
        multiple={true}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="file-input" className="button">
      {translations[language]['browse']}
      </label>
      <div className="progress-bar-background" style={uploadProgress > 0 ? null : {display: 'none'}}>
      <div
        className="progress-bar-fill"
        style={{ width: `${uploadProgress}%` }}
      ></div>
    </div>
    </div>
  )
}

export default DragAndDrop
